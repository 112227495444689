import Container from 'components/ui/Container'
import { SectionLayoutSettings } from 'framework/common/types'
import {
  CmsFeatureAlternatingSection,
  CommonSectionSettingsContainerEnum,
} from 'framework/strapi/types'
import React, { FC } from 'react'
import Feature from './Feature'

interface Props extends CmsFeatureAlternatingSection {
  sectionSettings: SectionLayoutSettings
}

const AlternatingFeaturesSection: FC<Props> = ({ settings, features }) => {
  const { container, colorTheme } = settings

  // Container
  const mainContainer: CommonSectionSettingsContainerEnum =
    container === 'Default' ? 'Fluid' : container

  if (!features || !features.length) return null

  return (
    <Container
      layout={mainContainer}
      height="Grow"
      align="Center"
      overflow="XHidden"
    >
      <div className="flex flex-col gap-y-12 lg:gap-y-14">
        {/*  -mt-10 md:mt-0 / tbd: if container is full and has Background! */}
        {features?.map((feature) => (
          <Feature
            key={feature.id}
            {...feature}
            colorTheme={colorTheme}
            container={mainContainer}
          />
        ))}
      </div>
    </Container>
  )
}

export default AlternatingFeaturesSection
