import { FC, useEffect, useState } from 'react'
import InnerHTML from 'dangerously-set-html-content'
import Spinner from '../../ui/Spinner'

interface Props {
  html: string | null
}

const Html: FC<Props> = ({ html }) => {
  // Make sure shell.js is loaded in Head (required for Hubspot Forms)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const loadScript = () => {
    const existingScript = document.getElementById('hubspotFormsShell')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = '//js.hsforms.net/forms/shell.js'
      script.id = 'hubspotFormsShell'
      document.body.appendChild(script)
      script.onload = () => {
        checkHubspot()
      }
    }

    if (existingScript) {
      // console.log('script exists')
      checkHubspot()
    }
  }

  const checkHubspot = () => {
    if ('hbspt' in window) {
      // console.log('hubspot script is completely loaded')
      setScriptLoaded(true)
    } else {
      // Try to load script again
      setTimeout(() => loadScript(), 500)
    }
  }

  useEffect(() => {
    loadScript()
  })

  return html ? (
    <>
      {/* <Script
        // id="hubspotFormsShell"
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/shell.js"
        onLoad={() => setScriptLoaded(true)}
      /> */}

      {scriptLoaded ? (
        <div className="w-full mt-2 bg-primary pt-8 pb-2 px-0 md:px-6 rounded-md flex-1">
          <InnerHTML html={html} />
        </div>
      ) : (
        <Spinner className="w-6 h-6" />
      )}
    </>
  ) : null
}

export default Html
