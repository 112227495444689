import cn from 'classnames'
import ButtonWrapper from 'components/ui/ButtonWrapper'
import Container, { ContainerLayout } from 'components/ui/Container'
import {
  CmsFeatureAlternatingFeature,
  CommonSectionSettingsColorThemeEnum,
} from 'framework/strapi/types'
import React, { FC } from 'react'
import Gallery from '../Gallery/Gallery'
import { textLineBreaks } from 'utils/textLineBreaks'
import Html from 'components/common/Html'
import RichText from 'components/common/RichText'
import Button from 'components/ui/Button'

interface Props extends CmsFeatureAlternatingFeature {
  colorTheme: CommonSectionSettingsColorThemeEnum
  container: ContainerLayout
}

const Feature: FC<Props> = ({
  overline,
  heading,
  text,
  html,
  ctas,
  galleryPlacement,
  gallery,
  colorTheme,
  container,
}) => {
  const css = {
    featureOverline: 'font-medium text-h-sm md:text-h-base',
    featureHeading: 'font-bold text-h-2xl md:text-h-3xl xl:text-h-4xl',
    featureText:
      'font-medium text-base md:text-lg 2xl:text-xl 2xl:leading-normal prose',
    featureHtml: 'bg-black pt-6 rounded-md md:pt-8 md:pb-2 lg:rounded-lg',
    featureRow:
      'flex items-center gap-x-8 lg:gap-x-20 flex-wrap lg:flex-nowrap',
    featureRowReverse:
      'flex items-center gap-x-8 lg:gap-x-20 flex-wrap lg:flex-nowrap lg:flex-row-reverse',
  }

  switch (colorTheme) {
    case 'Dark':
      css.featureOverline = cn(css.featureOverline, 'text-white')
      css.featureHeading = cn(css.featureHeading, 'text-white')
      css.featureText = cn(css.featureText, 'text-white')
      break
    default:
      css.featureOverline = cn(css.featureOverline, 'text-primary-2')
      css.featureHeading = cn(css.featureHeading, 'text-primary')
      css.featureText = cn(css.featureText, 'text-primary')
      css.featureHtml = cn(css.featureHtml, 'text-primary')
  }

  // Add line breaks to text
  text = textLineBreaks(text)

  //Container
  let textContainer: ContainerLayout =
    container === 'FullFluid' ? 'FluidFull' : 'Default'

  if (container === 'Full') {
    textContainer = 'FluidRight'
  }

  return (
    // Feature Row
    <div
      className={
        galleryPlacement === 'Right' ? css.featureRowReverse : css.featureRow
      }
    >
      {/* Gallery Column */}
      <div className="w-full lg:w-1/2 lg:flex-1">
        <div className="w-full">
          {gallery?.items && gallery.items.length > 0 && (
            <Gallery
              colorTheme={colorTheme}
              {...gallery}
              container={container}
            />
          )}
        </div>
      </div>

      {/* Text Column */}

      <div
        className={cn(
          `w-full lg:mt-0 lg:w-1/2 lg:max-w-full lg:flex-1 flex ${
            galleryPlacement === 'Right' ? 'lg:justify-end' : 'lg:justify-start'
          }`,
          { 'mt-6 md:mt-8': !!(gallery?.items && gallery.items.length > 0) },
        )}
      >
        <Container layout={textContainer}>
          <div className="flex flex-col w-full max-w-xl 2xl:max-w-2xl gap-y-8 lg:gap-y-10 xl:gap-y-12 2xl:gap-y-14">
            <div className="flex flex-col gap-y-4 md:gap-y-6 xl:gap-y-8">
              {(overline || heading) && (
                <div className="flex flex-col gap-y-2">
                  {overline && (
                    <p className={css.featureOverline}>{overline}</p>
                  )}
                  {heading && <h2 className={css.featureHeading}>{heading}</h2>}
                </div>
              )}
              {text && <RichText className={css.featureText}>{text}</RichText>}
              <Html html={html} />
            </div>
            {ctas && ctas.length > 0 && (
              <ButtonWrapper>
                {ctas.map(
                  (cta, index: number) =>
                    cta.title &&
                    cta.url?.src && (
                      <Button
                        key={index}
                        href={cta.url.src}
                        color={index === 0 ? 'primary' : 'secondary'}
                        colorTheme={colorTheme}
                      >
                        {cta.title}
                      </Button>
                    ),
                )}
              </ButtonWrapper>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Feature
